import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import React, { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import { setIn } from "final-form";
import { header, SafeKaroUser } from "../../../../context/constant";
import { useNavigate } from "react-router-dom";
import { IAddArea, IEditArea } from "../IArea";
import EditAreaService from "../../../../api/Area/EditArea/EditAreaService";
import AddAreaService from "../../../../api/Area/AddArea/AddAreaService";
import useGetState from "../../../../Hooks/State/useGetState";
import { ICity } from "../../City/ICity";
import GetCityByStateIdService from "../../../../api/City/GetCityByStateId/GetCityByStateIdService";
interface AreaPros {
  id?: string;
  isActive?: boolean;
  area?: string;
  state?: string;
  city?: string;
}

const AddAreaForm: FC<AreaPros> = ({
  id = "",
  area,
  isActive,
  state,
  city,
}) => {
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const states = useGetState();
  const title = id ? "Edit Area" : "Add Area";
  const navigate = useNavigate();
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [cities, setCities] = useState<ICity[]>([]);
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    state: yup
      .string()
      .required("state Name is required")
      .min(1, "state must be at least 1 character")
      .max(100, "state cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callAddAreaApi = async (data: IAddArea) => {
    try {
      const res = await AddAreaService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callEditAreaApi = async (data: IEditArea) => {
    const res = await EditAreaService(data);
    if (res.status.toLowerCase() === "success") {
      navigate(-1);
    }
    try {
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const onSubmit = async (data: any) => {
    const { area } = data;
    if (id && area) {
      let payload: IEditArea = { header, id, area };
      callEditAreaApi(payload);
    } else {
      const payload: IAddArea = {
        header,
        stateId: selectedStateId,
        cityId: selectedCityId,
        area,
        createdBy: userData.name,
      };
      callAddAreaApi(payload);
    }
  };

  const handleStateChange = async (newValue: any) => {
    setSelectedStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ id, area, isActive, state, city }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              {!id && (
                <Grid item xs={12} sm={6}>
                  <Field name="state">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="state"
                          value={
                            input.value !== undefined
                              ? input.value
                              : state || null
                          }
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            if (!id) {
                              input.onChange(newValue.state);
                              handleStateChange(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select State"
                              variant="outlined"
                              className="rounded-sm"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              {selectedStateId && cities && (
                <Grid item xs={12} sm={6}>
                  <Field name="city">
                    {({ input, meta }) => (
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="city"
                          value={
                            input.value !== undefined
                              ? input.value
                              : city || null
                          }
                          options={cities}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.city || ""
                          }
                          onChange={(event, newValue) => {
                            if (!id) {
                              input.onChange(newValue.city);
                              setSelectedCityId(newValue._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select City"
                              variant="outlined"
                              className="rounded-sm"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              )}
              <Grid item xs={6}>
                <Field name="area">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter Area"
                      type="text"
                      variant="outlined"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddAreaForm;
