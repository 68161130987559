import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editRfqEp as endpoint } from "../apiEndpoints";
import { IEditRFQ } from "../IRfq";


const EditRFQAPI = async ({
    id, rfq
}: IEditRFQ) => {
    const url = endpoint(id)
    const options: FetchOptions = {
        method: "PUT",
        body: rfq,
    }

    return fetchInterceptor(url, options)


};

export default EditRFQAPI;