import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getCityByStateIdEp as endpoint } from '../apiEndpoints';
import { IGetCitiesByStateId } from "../../../components/Admin/City/ICity";


const GetCityByStateIdAPI = async ({ header,stateId }: IGetCitiesByStateId) => {
  const url = endpoint(stateId)
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }

  return fetchInterceptor(url, options)

 
};

export default GetCityByStateIdAPI;
