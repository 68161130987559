import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editAreaByIdEp as endPoint } from "../apiEndpoints";

import { IEditArea } from "../../../components/Admin/Area/IArea";

const EditAreaAPI = async ({ header, id, isActive, area }: IEditArea) => {
  const url = endPoint(id)
  const options: FetchOptions = {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
      id, isActive, area
    }),
  }
  return fetchInterceptor(url, options)

};

export default EditAreaAPI;