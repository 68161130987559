export const getRfqEp = () => {
    return ("/api/rfq")
}
export const getRfqByIdEp = (RfqId: string) => {
    return (`/api/rfq/${RfqId}`)
}
export const editRfqEp = (RfqId: string) => {
    return (`/api/rfq/${RfqId}`)
}

export const addRfqEp = () => {
    return ("/api/rfq")
}