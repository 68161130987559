import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getCitiesEp as endpoint } from '../apiEndpoints';
import { IGetAllCities } from "../../../components/Admin/City/ICity";


const GetAllCitiesAPI = async ({ header }: IGetAllCities) => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }
  return fetchInterceptor(url, options)
};

export default GetAllCitiesAPI;