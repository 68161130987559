import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addStateEp as Endpoint} from "../apiEndpoints";
import { IAddState } from "../../../components/Admin/State/IState";

const AddStateAPI = async ({ header, state,createdBy }:IAddState ) => {
  const url = Endpoint()
  const options: FetchOptions= {
    method: "POST",
    headers: header,
    body: JSON.stringify({
     state,createdBy
    })
  }
  return fetchInterceptor(url, options)
  
};

export default AddStateAPI;