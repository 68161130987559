import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editCityByIdEp as endPoint } from "../apiEndpoints";

import { IEditCity} from "../../../components/Admin/City/ICity";

const EditCityAPI = async ({ header, stateId,id,city,isActive }: IEditCity) => {
  const url = endPoint(id)
  const options: FetchOptions= {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
      stateId,id,city,isActive
    }),
  }
  return fetchInterceptor(url, options)

};

export default EditCityAPI;