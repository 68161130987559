import { IAddRFQ } from "../IRfq";
import AddRFQAPI from "./AddRFQAPI";

const AddRFQService = async ({ rfq }: IAddRFQ): Promise<any> => {
    try {
        const res = await AddRFQAPI({
            rfq
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default AddRFQService;
