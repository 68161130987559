import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { editStateByIdEp as endPoint } from "../apiEndpoints";

import { IEditState } from "../../../components/Admin/State/IState";

const EditStateAPI = async ({ header, state,id,updatedBy,isActive }: IEditState) => {
  const url = endPoint(id)
  const options: FetchOptions= {
    method: "PUT",
    headers: header,
    body: JSON.stringify({
     state,updatedBy,isActive
    }),
  }
  return fetchInterceptor(url, options)

};

export default EditStateAPI;