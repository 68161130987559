import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ICity } from "../ICity";
import AddCityFrom from "./AddCityFrom";

const AddCity = () => {
  const location = useLocation();
  const city = location.state as ICity;
  const title = city && city._id ? "Edit City" : "Add City";

  
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">


    <Paper elevation={3} style={{ padding: 30 }}>
      <Typography className="text-safekaroDarkOrange" variant="h5">
        {title.toUpperCase()}
      </Typography>
      <Typography variant="h5" mb={2}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <Link to="/dashboard" className="text-addButton font-bold text-sm">
              Dashboard /
            </Link>
            <Link to="/state" className="text-addButton font-bold text-sm">
              cities /
            </Link>

            <span className="text-grey-600 text-sm"> {title}</span>
          </div>
        </div>
        <div className="my-2"></div>
      </Typography>
      {city ? <AddCityFrom initialValues={city} /> : <AddCityFrom />}
    </Paper>
    </div>
  );
};

export default AddCity;
