

import { IEditCity } from "../../../components/Admin/City/ICity";
import EditCityAPI from "./EditCityAPI";
const EditCityService = async ({ header, id, stateId, city,isActive }: IEditCity): Promise<any> => {
    try {
        const res = await EditCityAPI({
            header, id, stateId, city,isActive

        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditCityService;