
import EditStateAPI from "./EditStateAPI";
import { IEditState } from "../../../components/Admin/State/IState";

const EditSateService = async ({ header, id, updatedBy, state,isActive }: IEditState): Promise<any> => {
    try {
        const res = await EditStateAPI({
            header, id, updatedBy, state,isActive

        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditSateService;