import { IAddCity } from "../../../components/Admin/City/ICity";
import AddCityAPI from "./AddCityAPI";

const AddCityService = async ({
    header,
    stateId,createdBy,city
}: IAddCity): Promise<any> => {
    try {
        const res = AddCityAPI({
            header: header,
            stateId,createdBy,city
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default AddCityService;