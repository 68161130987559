

import { IGetCitiesByStateId } from "../../../components/Admin/City/ICity";
import GetCityByStateIdAPI from "./GetCityByStateIdAPI";

const GetCityByStateIdService = async ({ header, stateId }: IGetCitiesByStateId): Promise<any> => {
    try {
        const res = await GetCityByStateIdAPI({
            header: header, stateId
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetCityByStateIdService;