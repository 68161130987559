import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useGetState from "../../../Hooks/State/useGetState";
import {  IEditState, IState } from "./IState";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { header } from "../../../context/constant";
import toast, { Toaster } from "react-hot-toast";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditSateService from "../../../api/State/EditSate/EditSateService";
const State = () => {
  const navigate = useNavigate();
  const data = useGetState();
  const [states, setState] = useState<IState[] | undefined>();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const columns = useMemo<MRT_ColumnDef<IState>[]>(
    () => [
      {
        accessorKey: "state",
        header: "State",
        size: 200,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },
      {
        header: "Status",
        accessorKey: "isActive",
        size: 50,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (data.length > 0) {
      setState(data);
    }
  }, [data]);
  const handleClickEditState = (data: IState) => {
    navigate("edit-state", { state: { id: data._id, state: data.state } });
  };

  const callEditStateApi = async (data: IEditState) => {
    const res = await EditSateService(data);
    if (res.status.toLowerCase() === "success") {
      const filter = states?.filter((ele) => ele._id !== data.id);

      if (filter) {
        setState([...filter]);
      }
    }
    try {
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleClickDeleteState = async (row: IState) => {
    const payload: IEditState = {
      header,
      id: row._id,
      state: row.state,
      isActive: !row.isActive,
    };
    callEditStateApi(payload);
  };
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-[100%]">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          State Table
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <span className="text-grey-600 text-sm"> state</span>
            </div>
            <Link to="add-state">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add State
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={states || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit State"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit State"}
                  component="span"
                  onClick={() => {
                    handleClickEditState(row.original as IState);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="error"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() => {
                    handleClickDeleteState(row.original as IState);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

export default State;
