import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IArea } from "../IArea";
import AddAreaForm from "./AddAreaForm";

const AddArea = () => {
  const loc = useLocation();
  const row = loc.state as IArea;
  const title = row?._id ? "Edit Area" : "Add Area";

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/area" className="text-addButton font-bold text-sm">
                area /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2"></div>
        </Typography>
        {row ? <AddAreaForm id={row._id} area={row.area} state={row.stateName} city={row.cityName}/> : <AddAreaForm />}
      </Paper>
    </div>
  );
};

export default AddArea;
