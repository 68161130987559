import { Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { IRfq } from "./RFQType";

import { header } from "../../../context/constant";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import dayjs from "dayjs";
import EditRFQService from "../../../api/RequestForQueue/EditRFQ/EditRFQService";
import GetRFQService from "../../../api/RequestForQueue/GetRFQ/GetRFQService";
const RequestForQueue = () => {
  const [requestForQueueData, setRequestForQueueData] = useState<IRfq[]>();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const res = await GetRFQService({ header });
      if (res.status.toLowerCase() === "success") {
        setRequestForQueueData(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<IRfq>[]>(
    () => [
      {
        accessorKey: "categoryName",
        header: "Category Name",
        size: 200,
      },
      {
        accessorKey: "productName",
        header: "Product Name",
        size: 200,
      },
      {
        accessorKey: "customerName",
        header: "Customer Name",
        size: 200,
      },
      {
        accessorKey: "fullName",
        header: "Full Name",
        size: 200,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 250,
      },
      {
        accessorKey: "pincode",
        header: "Pin Code",
        size: 100,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 200,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "phone",
        header: "Phone",
        size: 150,
      },

      {
        accessorKey: "createdBy",
        header: "Created By",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Created On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return <span>{dayjs(value).format("DD MMM YYYY")}</span>;
        },
      },

      {
        accessorKey: "updatedOn",
        header: "Updated On",
        size: 200,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? (
            <span>{dayjs(value).format("DD MMM YYYY")}</span>
          ) : (
            "N/A"
          );
        },
      },
      {
        accessorKey: "isActive",
        header: "Active Status",
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue<boolean>();
          return value ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  const handleChangeStatus = async (row: IRfq) => {
    const formData = new FormData();
    formData.append("isActive", (!row.isActive).toString());
    try {
      const res = await EditRFQService({
        id: row._id!,
        rfq: formData,
      });

      if (res.status === "success") {
        const newData = requestForQueueData?.filter((ele) => {
          return ele._id !== row._id;
        });
        if (newData) {
          setRequestForQueueData([...newData]);
        } else {
          setRequestForQueueData([]);
        }
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const handleClickEditReq = (row: IRfq) => {
    navigate("/lead/edit-request", { state: row });
  };
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          Request For Queue
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard{" "}
              </Link>
            </div>
            <Link to="/lead/add-request">
              <Button
                type="button"
                className="w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
              >
                Add Request
              </Button>
            </Link>
          </div>
        </Typography>
        <MaterialReactTable
          state={{ pagination }}
          columns={columns}
          data={requestForQueueData || []}
          enableRowActions
          onPaginationChange={setPagination}
          autoResetPageIndex={false}
          paginateExpandedRows={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <div style={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title={"Edit Request"}>
                <IconButton
                  color="primary"
                  aria-label={"Edit Area"}
                  component="span"
                  onClick={() => {
                    handleClickEditReq(row.original as IRfq);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Change Status"}>
                <IconButton
                  color="error"
                  aria-label={"Change Status"}
                  component="span"
                  onClick={() => handleChangeStatus(row.original)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-5 text-addButton"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
      </Paper>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

export default RequestForQueue;
