import { IAddArea } from "../../../components/Admin/Area/IArea";
import AddAreaAPI from "./AddAreaAPI";

const AddAreaService = async ({
    header,
    stateId, createdBy, cityId, area
}: IAddArea): Promise<any> => {
    try {
        const res = AddAreaAPI({
            header: header,
            stateId, createdBy, cityId, area
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default AddAreaService;