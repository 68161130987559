import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getStateEp as endpoint } from '../apiEndpoints';
import { IGetState } from "../../../components/Admin/State/IState";


const GetAllStateAPI = async ({ header }: IGetState) => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }

  return fetchInterceptor(url, options)

 
};

export default GetAllStateAPI;
