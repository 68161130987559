
import fetchInterceptor, { FetchOptions } from '../../../utils/fetchInterceptor ';

import { getRfqEp as endpoint } from '../apiEndpoints';
import { IGetRFQ } from '../IRfq';
const GetRFQAPI = async ({ header }: IGetRFQ) => {
    const url = endpoint()
    const options: FetchOptions = {
        method: "GET",
        headers: header,
    }

    return fetchInterceptor(url, options)

};

export default GetRFQAPI;