import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getAreaEp as endpoint } from '../apiEndpoints';
import { IGetAllAreas } from "../../../components/Admin/Area/IArea";


const GetAllAreasAPI = async ({ header }: IGetAllAreas) => {
  const url = endpoint()
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }
  return fetchInterceptor(url, options)
};

export default GetAllAreasAPI;