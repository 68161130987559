

import { IGetAllCities } from "../../../components/Admin/City/ICity";
import GetAllCitiesAPI from "./GetAllCitiesAPI";

const GetAllCitiesServices = async ({ header }: IGetAllCities): Promise<any> => {
    try {
        const res = await GetAllCitiesAPI({
            header: header
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetAllCitiesServices;