
import { IGetRFQ } from "../IRfq";
import GetRFQAPI from "./GetRFQAPI";
const GetRFQService = async ({ header }: IGetRFQ): Promise<any> => {
    try {
        const res = await GetRFQAPI({ header });
        return res;
    } catch (error) {
        throw error
    }
};

export default GetRFQService;