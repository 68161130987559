

import { IGetAllAreas } from "../../../components/Admin/Area/IArea";
import GetAreaByCityIdAPI from "./GetAreaByCityIdAPI";


const GetAreaByCityIdServices = async ({ header, id }: IGetAllAreas): Promise<any> => {
    try {
        const res = await GetAreaByCityIdAPI({
            header: header, id
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetAreaByCityIdServices;