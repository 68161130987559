import { Button, Grid, TextField } from "@mui/material";
import React, { FC } from "react";
import { Field, Form } from "react-final-form";
import toast, { Toaster } from "react-hot-toast";
import { IAddState, IEditState } from "../IState";
import * as yup from "yup";
import { setIn } from "final-form";
import { header, SafeKaroUser } from "../../../../context/constant";
import AddStateService from "../../../../api/State/AddState/AddStateService";
import { useNavigate } from "react-router-dom";
import EditSateService from "../../../../api/State/EditSate/EditSateService";
interface StatePros {
  id?: string;
  state?: string;
}

const AddStateFrom: FC<StatePros> = ({ id, state }) => {
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const title = id ? "Edit State" : "Add State";
  const navigate = useNavigate();
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };
  const validationSchema = yup.object().shape({
    state: yup
      .string()
      .required("state Name is required")
      .min(1, "state must be at least 1 character")
      .max(100, "state cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callAddStateApi = async (data: IAddState) => {
    try {
      const res = await AddStateService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callEditStateApi = async (data: IEditState) => {
    const res = await EditSateService(data);
    if (res.status.toLowerCase() === "success") {
      navigate(-1);
    }
    try {
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const onSubmit = async (data: any) => {
    const { state } = data;
    if (id) {
      let payload: IEditState = { header, state, id, updatedBy: userData.name };
      callEditStateApi(payload);
    } else {
      const payload: IAddState = { header, state, createdBy: userData.name };
      callAddStateApi(payload);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ id, state }}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <Field name="state">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      label="Enter State"
                      type="text"
                      variant="outlined"
                      size="small"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddStateFrom;
