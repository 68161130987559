
export const addCityEp=()=>{
    return("/api/non-motor/city")
}

export const getCityByStateIdEp=(stateId:string)=>{
    return(`/api/non-motor/city/state-id/${stateId}`)
}
export const editCityByIdEp=(id:string)=>{
    return(`/api/non-motor/city/${id}`)
}
export const deleteCityByIdEp=(id:string)=>{
    return(`/api/non-motor/city/${id}`)
}
export const getCitiesEp=()=>{
    return(`/api/non-motor/city`)
}