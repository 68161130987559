
import GetAllStateAPI from "./GetAllStateAPI";
import { IGetState } from "../../../components/Admin/State/IState";

const GetAllStateService = async ({ header }: IGetState): Promise<any> => {
    try {
        const res = await GetAllStateAPI({
            header: header,
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetAllStateService;