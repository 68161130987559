import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addAreaEp as Endpoint } from "../apiEndpoints";
import { IAddArea } from "../../../components/Admin/Area/IArea";

const AddAreaAPI = async ({ header, stateId, createdBy, cityId,area }: IAddArea) => {
  const url = Endpoint()
  const options: FetchOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      stateId, createdBy, cityId,area
    })
  }
  return fetchInterceptor(url, options)

};

export default AddAreaAPI;