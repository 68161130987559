import { useEffect, useRef, useState } from "react";
import { IState } from "../../components/Admin/State/IState";
import { header } from "../../context/constant";
import GetAllStateService from "../../api/State/GetAllState/GetAllStateService";
export const defaultRole: IState[] = [];

const useGetState = () => {
  const [states, setStates] = useState<IState[]>(defaultRole);
  const isLoading = useRef(true);
  useEffect(() => {
    if (isLoading.current) {
        GetAllStateService({ header })
        .then((apiResponse) => {
          isLoading.current = false;
          setStates(apiResponse.data!);
        })
        .catch((res) => {
          throw res.status;
      
        });
    }
     // eslint-disable-next-line
  }, [header, isLoading]);

  return states;
};

export default useGetState;