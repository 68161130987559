import { Paper, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddStateFrom from "./AddStateFrom";

interface StatePros {
  id?: string;
  state?: string;
}
const AddState = () => {
  const stateData = useLocation().state as StatePros;
  const title = (stateData &&  stateData.id )? "Edit State" : "Add State";

  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title.toUpperCase()}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link to="/state" className="text-addButton font-bold text-sm">
                state /
              </Link>

              <span className="text-grey-600 text-sm"> {title}</span>
            </div>
          </div>
          <div className="my-2">

          <AddStateFrom id={stateData && stateData.id} state={stateData && stateData.state} />
          </div>
        </Typography>
      </Paper>
    </div>
  );
};

export default AddState;
