

import { IEditArea } from "../../../components/Admin/Area/IArea";
import EditAreaAPI from "./EditAreaAPI";
const EditAreaService = async ({ header, id, area, isActive }: IEditArea
): Promise<any> => {
    try {
        const res = await EditAreaAPI({
            header, id, area, isActive

        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditAreaService;