
export const addAreaEp=()=>{
    return("/api/non-motor/area")
}
export const editAreaByIdEp=(id:string)=>{
    return(`/api/non-motor/area/${id}`)
}

export const getAreaEp=()=>{
    return(`/api/non-motor/area`)
}
export const getAreaByCityIdEp=(cityId:string)=>{
    return(`/api/non-motor/area/city-id/${cityId}`)
}