import { IEditRFQ } from "../IRfq";
import EditRFQAPI from "./EditRFQAPI";
;

const EditRFQService = async ({ id, rfq }: IEditRFQ): Promise<any> => {
    try {
        const res = await EditRFQAPI({
            id,
            rfq
        })
        return res;
    } catch (error) {
        throw error
    }

};

export default EditRFQService;
