import React, { FC } from "react";
import { IAddCity, ICity, IEditCity } from "../ICity";
import toast, { Toaster } from "react-hot-toast";
import useGetState from "../../../../Hooks/State/useGetState";
import { Field, Form } from "react-final-form";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { setIn } from "final-form";
import { SafeKaroUser } from "../../../../context/constant";
import { header } from "../../../../context/constant";
import EditCityService from "../../../../api/City/EditCity/EditCityService";
import { useNavigate } from "react-router-dom";
import AddCityService from "../../../../api/City/AddCity/AddCityService";
interface initialValuesProps {
  initialValues?: ICity | undefined;
}

const AddCityFrom: FC<initialValuesProps> = ({ initialValues }) => {
  const states = useGetState();
  const title = initialValues?._id ? "Edit City" : "Add City";
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null;
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const navigate = useNavigate();

  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };

  const validationSchema = yup.object().shape({
    //categoryName: yup.string().required("Category Name is required"),
    city: yup
      .string()
      .required("city Name is required")
      .min(1, "city must be at least 1 character")
      .max(100, "city cannot exceed 100 characters"),
  });

  const validate = validateFormValues(validationSchema);

  const callEditCityApi = async (data: IEditCity) => {
    try {
      const res = await EditCityService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const callAddCityApi = async (data: IAddCity) => {
  
    try {
      const res = await AddCityService(data);
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const onSubmit = async (data: any) => {
    const { city } = data;
    if (initialValues?._id) {
      let stateId;
      if (data.stateId) {
        stateId = data.stateId;
      }
      if (data.stateName._id) {
        stateId = data.stateName._id;
      }
      const payload: IEditCity = {
        header,
        stateId,
        city,
        id: initialValues._id,
      };
      callEditCityApi(payload);
    } else {
      const payload: IAddCity = {
        header,
        stateId: data.stateName._id,
        createdBy: userData.name,
        city,
      };
      callAddCityApi(payload);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, errors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field name="stateName">
                  {({ input, meta }) => (
                    <div>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          {...input}
                          id="stateName"
                          value={
                            input.value !== undefined
                              ? input.value
                              : initialValues?.stateName || null
                          }
                          options={states}
                          getOptionLabel={(option) =>
                            typeof option === "string"
                              ? option
                              : option.state || ""
                          }
                          onChange={(event, newValue) => {
                            input.onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=" Select State"
                              className="rounded-sm w-full"
                              size="small"
                              variant="outlined"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item sm={6}>
                <Field name="city">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      size="small"
                      label="Enter City Name"
                      variant="outlined"
                      className="rounded-sm w-full"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  className=" w-26 h-10 bg-addButton text-white p-3 text-xs rounded-sm"
                >
                  {title}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};

export default AddCityFrom;
