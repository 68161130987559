import { IAddState } from "../../../components/Admin/State/IState";
import AddStateAPI from "./AddStateAPI";

const AddStateService = async ({
    header,
    state,createdBy
}: IAddState): Promise<any> => {
    try {
        const res = AddStateAPI({
            header: header,
            state,createdBy
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export default AddStateService;