import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import {
  ALLOWED_FILE_TYPES,
  DAY_FORMAT,
  Document,
  MAX_FILE_SIZE,
  SafeKaroUser,
} from "../../../../context/constant";
import { Field, Form } from "react-final-form";
import { documentTypes } from "../../../Policy/IPolicyData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { setIn } from "final-form";
import { header } from "../../../../context/constant";
import { IProducts } from "../../../Admin/Product/IProduct";
import getProductService from "../../../../api/Product/GetProducts/getProductsService";
import { IAddRfq, InitialValueProps, RFQProps } from "../RFQType";
import { IArea } from "../../../Admin/Area/IArea";
import { ICity } from "../../../Admin/City/ICity";
import useGetState from "../../../../Hooks/State/useGetState";
import GetAreaByCityIdServices from "../../../../api/Area/GetAreaByCityId/GetAreaByCityIdServices";
import GetCityByStateIdService from "../../../../api/City/GetCityByStateId/GetCityByStateIdService";
import { useNavigate } from "react-router-dom";
import generateFormData from "../../../nonMotor/uitils/generateFormData";
import EditRFQService from "../../../../api/RequestForQueue/EditRFQ/EditRFQService";
import AddRFQService from "../../../../api/RequestForQueue/AddRFQ/AddRFQService";
const AddReqQueueFrom: FC<RFQProps> = ({ propsData }) => {
  const [category, setCategory] = useState(propsData?.categoryName);
  const [productData, setProductData] = useState<IProducts[]>();
  const navigate = useNavigate();
  const states = useGetState();
  const [selectedStateId, setSelectedStateId] = useState(propsData?.stateId);
  const [selectedCityId, setSelectedCityId] = useState(propsData?.cityId);
  const [area, setArea] = useState<IArea[]>();
  const [cities, setCities] = useState<ICity[]>([]);
  let storedTheme: any = localStorage.getItem("user") as SafeKaroUser | null; // Retrieve user info from local storage
  let userData = storedTheme ? JSON.parse(storedTheme) : storedTheme;
  const handleCityChange = async (newValue: any) => {
    setSelectedCityId(newValue._id);
    try {
      const res = await GetAreaByCityIdServices({
        header,
        id: newValue._id,
      });
      if (res.status === "success") {
        setArea(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const handleStateChange = async (newValue: any) => {
    setSelectedStateId(newValue._id);
    try {
      const res = await GetCityByStateIdService({
        header,
        stateId: newValue._id,
      });
      if (res.status === "success") {
        setCities(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const fetchProduct = async () => {
    try {
      if (category) {
        const res = await getProductService({ header, category });
        setProductData(res.data);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };

  const generateInitialData = () => {
    if (propsData) {
      const {
        address,
        email,
        fullName,
        phone,
        pincode,
        state,
        city,
        customerName,
        renewalDate,
      } = propsData;
      const product: IProducts = {
        _id: propsData.productId,
        categoryId: propsData.categoryId,
        categoryName: propsData.categoryName,
        productName: propsData.productName,
      };
      const area: IArea = {
        _id: propsData.areaId ?? "",
        area: propsData.area ?? "",
        cityId: propsData.cityId ?? "",
        cityName: propsData.city,
        stateId: propsData.stateId ?? "",
        stateName: propsData.state,
      };

      const result: InitialValueProps = {
        product,
        area,
        pincode,
        fullName,
        address,
        email,
        city,
        state,
        phone,
        customerName,
        renewalDate: dayjs(renewalDate).toISOString(),
        category: category || product.categoryName,
      };
      console.log(result);
      return result;
    }
  };
  const callAddRFQApi = async (payload: IAddRfq) => {
    try {
      const res = await AddRFQService({ rfq: generateFormData(payload) });
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  const callEditRFQApi = async (payload: IAddRfq) => {
    try {
      const res = await EditRFQService({
        id: propsData?._id!,
        rfq: generateFormData(payload),
      });
      if (res.status.toLowerCase() === "success") {
        navigate(-1);
      }
    } catch (error: any) {
      const err = await error;
      toast.error(err.message);
    }
  };
  useEffect(() => {
    if (category) {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, [category]);

  const onSubmit = (data: any) => {
    const {
      product: { productName, _id, categoryName, categoryId },
      renewalDate,
      fullName,
      email,
      phone,
      address,
      pincode,
      customerName,
      state,
      city,
      file,
    } = data;

    const formattedRenewalDate = dayjs(renewalDate).format(DAY_FORMAT);
    const { area, _id: areaId } = data.area;

    const formDataPayload: IAddRfq = {
      renewalDate: formattedRenewalDate,
      productName,
      categoryName,
      categoryId,
      productId: _id,
      stateId: selectedStateId!,
      cityId: selectedCityId!,
      fullName,
      email,
      phone,
      address,
      pincode,
      customerName,
      area,
      areaId,
      state,
      city,
      file,
      createdBy: userData.name,
    };

    if (propsData) {
      callEditRFQApi(formDataPayload);
    } else {
      callAddRFQApi(formDataPayload);
    }
  };
  const validateFormValues = (schema: any) => async (values: any) => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (err: any) {
      const errors = err.inner.reduce((formError: any, innerError: any) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      return errors;
    }
  };

  const validationSchema = yup.object().shape({
    address: yup
      .string()
      .required("Address is required")
      .max(200, "Address cannot exceed 200 characters"),
    pincode: yup
      .string()
      .required("Pincode is required")
      .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
    fullName: yup
      .string()
      .required("Full Name is required")
      .min(2, "Full Name should be at least 2 characters"),
    customerName: yup
      .string()
      .required("Customer Name is required")
      .min(2, "Customer Name should be at least 2 characters"),
    email: yup
      .string()
      .required("Email is required")
      .email("Email is not valid"),
    phone: yup
      .string()
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    file: yup.mixed().when([], {
      is: () => !propsData,
      then: yup
        .mixed()
        .required("File is required")
        .test("fileSize", "File size must be less than 2MB", (value) => {
          return value && value.size <= MAX_FILE_SIZE;
        })
        .test("fileType", "Only PDF files are allowed", (value) => {
          return value && value.type === "application/pdf";
        }),
      otherwise: yup
        .mixed()
        .test("fileSize", "File size must be less than 2MB", (value) => {
          return !value || value.size <= MAX_FILE_SIZE;
        })
        .test("fileType", "Only PDF files are allowed", (value) => {
          return !value || value.type === "application/pdf";
        }),
    }),
  });
  const validate = validateFormValues(validationSchema);
  return (
    <>
      <div className="bg-blue-200 p-1">
        <Paper elevation={3} style={{ padding: 20 }}>
          <Form
            mt={3}
            initialValues={generateInitialData()}
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, submitting, errors }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="category">
                      {({ input, meta }) => (
                        <FormControl fullWidth size="small">
                          <Autocomplete
                            {...input}
                            id="product"
                            value={
                              input.value !== undefined ? input.value : null
                            }
                            options={["Motor", "Non Motor"]}
                            getOptionLabel={(option) =>
                              typeof option === "string"
                                ? option
                                : option.productName || ""
                            }
                            onChange={(event, newValue) => {
                              input.onChange(newValue);
                              setCategory(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Category"
                                variant="outlined"
                                className="rounded-sm w-full"
                                size="small"
                                error={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  {category && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Field name="product">
                        {({ input, meta }) => (
                          <FormControl fullWidth size="small">
                            <Autocomplete
                              {...input}
                              id="product"
                              value={
                                input.value !== undefined ? input.value : null
                              }
                              options={productData || []}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option.productName || ""
                              }
                              onChange={(event, newValue) => {
                                input.onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Product"
                                  variant="outlined"
                                  className="rounded-sm w-full"
                                  size="small"
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && meta.error}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  )}

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="customerName">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Customer Name"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <span>Contact Person Details</span>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="fullName">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Name"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="email">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Email"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="phone">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Phone Number"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="state">
                      {({ input, meta }) => (
                        <div>
                          <FormControl fullWidth size="small">
                            <Autocomplete
                              {...input}
                              id="state"
                              value={
                                input.value !== undefined ? input.value : null
                              }
                              options={states}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option.state || ""
                              }
                              onChange={(event, newValue) => {
                                input.onChange(newValue.state);
                                handleStateChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select State"
                                  variant="outlined"
                                  className="rounded-sm w-full"
                                  size="small"
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && meta.error}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                      )}
                    </Field>
                  </Grid>
                  {selectedStateId && cities && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Field name="city">
                        {({ input, meta }) => (
                          <FormControl fullWidth size="small">
                            <Autocomplete
                              {...input}
                              id="city"
                              value={
                                input.value !== undefined ? input.value : null
                              }
                              options={cities}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option.city || ""
                              }
                              onChange={(event, newValue) => {
                                input.onChange(newValue.city);
                                handleCityChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select City"
                                  variant="outlined"
                                  className="rounded-sm w-full"
                                  size="small"
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && meta.error}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  )}
                  {selectedCityId && area && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Field name="area">
                        {({ input, meta }) => (
                          <FormControl fullWidth size="small">
                            <Autocomplete
                              {...input}
                              id="area"
                              value={
                                input.value !== undefined ? input.value : null
                              }
                              options={area}
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option.area || ""
                              }
                              onChange={(event, newValue) => {
                                input.onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select area"
                                  variant="outlined"
                                  className="rounded-sm w-full"
                                  size="small"
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched && meta.error}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  )}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="address">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Address"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="pincode">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Enter Pin Code"
                          type="text"
                          variant="outlined"
                          size="small"
                          className="rounded-sm w-full"
                          error={meta.touched && Boolean(meta.error)}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Field name="renewalDate">
                      {({ input, meta }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Renewal Date"
                            value={input.value || null}
                            onChange={(date) => input.onChange(date)}
                            renderInput={(params: any) => (
                              <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...params}
                                error={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Field name="file">
                      {({ input, meta }) => (
                        <div>
                          <Grid item lg={12} xs={12}>
                            <input
                              type="file"
                              style={{
                                border: "1px solid #c4c4c4",
                                padding: "5px",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const file = event.target.files
                                  ? event.target.files[0]
                                  : null;
                                input.onChange(file);
                              }}
                              accept=".pdf"
                            />
                            {meta.touched && meta.error && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </Grid>
                        </div>
                      )}
                    </Field>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button variant="contained" type="submit">
                      submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Paper>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} />
    </>
  );
};
export default AddReqQueueFrom;
