import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { addCityEp as Endpoint } from "../apiEndpoints";
import { IAddCity } from "../../../components/Admin/City/ICity";

const AddCityAPI = async ({ header, stateId, createdBy, city }: IAddCity) => {
  const url = Endpoint()
  const options: FetchOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      createdBy, stateId, city
    })
  }
  return fetchInterceptor(url, options)

};

export default AddCityAPI;