import fetchInterceptor, { FetchOptions } from "../../../utils/fetchInterceptor ";
import { getAreaByCityIdEp as endpoint } from '../apiEndpoints';
import { IGetAllAreas } from "../../../components/Admin/Area/IArea";


const GetAreaByCityIdAPI = async ({ header,id }: IGetAllAreas) => {
  const url = endpoint(id!)
  const options: FetchOptions = {
    method: "GET",
    headers: header,
  }
  return fetchInterceptor(url, options)
};

export default GetAreaByCityIdAPI;