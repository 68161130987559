import { Paper, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddReqQueueFrom from "./AddReqQueueFrom";
import { IRfq } from "../RFQType";

const AddReqQueue = () => {
  const loc = useLocation();
  const data = loc.state as IRfq;
  const title = data ? "Edit Request For Queue" : "Add Request For Queue";
  const navigate = useNavigate();
  return (
    <div className="bg-blue-200 md:p-7 p-2 h-screen">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Typography className="text-safekaroDarkOrange" variant="h5">
          {title}
        </Typography>
        <Typography variant="h5" mb={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Link
                to="/dashboard"
                className="text-addButton font-bold text-sm"
              >
                Dashboard /
              </Link>
              <Link
                to="/lead/rq-queue"
                className="text-addButton font-bold text-sm"
              >
                Request For Queue
              </Link>
            </div>
          </div>
        </Typography>
        {data? <AddReqQueueFrom propsData={data}/>: <AddReqQueueFrom />}
       
      </Paper>
    </div>
  );
};

export default AddReqQueue;
