

import { IGetAllAreas } from "../../../components/Admin/Area/IArea";
import GetAllCitiesAPI from "./GetAllAreasAPI";

const GetAllAreasServices = async ({ header }: IGetAllAreas): Promise<any> => {
    try {
        const res = await GetAllCitiesAPI({
            header: header
        })
        return res;
    } catch (error) {
        throw error;
    }

};

export default GetAllAreasServices;