
export const addStateEp=()=>{
    return("/api/non-motor/state")
}
export const getStateEp=()=>{
    return("/api/non-motor/state")
}
export const getStateByIdEp=(id:string)=>{
    return(`/api/non-motor/state/${id}`)
}
export const editStateByIdEp=(id:string)=>{
    return(`/api/non-motor/state/${id}`)
}
export const deleteStateByIdEp=(id:string)=>{
    return(`/api/non-motor/state/${id}`)
}